import axios from "axios";

// Main Axios instance
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Add an interceptor to automatically attach token for authorization
axiosInstance.interceptors.request.use(async (config) => {
    const token = JSON.parse(localStorage.getItem('accessToken')) // Assuming token is stored in localStorage
    console.log('token axiosInstance:>> ', token);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`; // Attach token to headers
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem("userData");
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

// Second Axios instance (same setup as first one)
const axiosSecondInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL_SECOND,
});

// Attach request interceptor to second instance
axiosSecondInstance.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('accessToken')) // Assuming token is stored in localStorage
    console.log('token axiosSecondInstance:>> ', token);

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Response interceptor for second instance
axiosSecondInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            console.log('true axios second:>> ', true);
            // localStorage.removeItem("userData");
            // window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

// Export the instances
export { axiosInstance, axiosSecondInstance };